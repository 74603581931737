import React, { useState, useEffect, useCallback } from "react";
import { Select, Spin, Skeleton, Divider, Typography, Space } from "antd";
import _debounce from "lodash/debounce";
import { LoadingOutlined } from "@ant-design/icons";
import { ReactComponent as EmailIcon } from "../../assets/icon_mail.svg";
import ContentTable from "../../components/ContentTable";
import PageHead from "../../components/PageHead";
import apiCall from "../../services/apiCall";
import ToastNotification from "../../helpers/ToastNotification";
import useViewPort from "../../services/responsiveHelper";
import ListClients from "../../components/ListClients";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory, useLocation } from "react-router-dom";
import { SourceConstants } from "../../helpers/Constants";import PopOver from "../../components/PopOver";

const {Text} = Typography;
const columns = [
    {
        title: "Workorder",
        dataIndex: "workOrderNo",
        key: "workOrderNo",
        width: "10%",
        render: (_text, record) => {
            const data = record?.workOrders || [];
            const remainingData = record?.workOrders?.slice(1) || [];


            if (data.length > 0) {
                const content = (
                    <div>
                        {remainingData.map((item, index) => (
                            <div key={index}>
                                <span>{item.workOrderNo}</span>
                            </div>
                        ))}
                    </div>
                );

                return (
                    <Space>
                        {data[0].workOrderNo}
                        {remainingData.length > 0 && (
                            <PopOver
                                content={content}
                            >
                                <Text className="popover-workorder-text" underline={true}>{`+${remainingData.length}`}</Text>
                            </PopOver>
                        )}
                    </Space>
                );
            }
        }
    },
    {
        title: "Proctor Name",
        dataIndex: ["firstName", "lastName"],
        key: "firstName",
        width: "15%",
        render: (_text, record) => (
            <span>
                {record.firstName} {record.lastName}
            </span>
        )
    },
    {
        title: "Proctor Email",
        dataIndex: "email",
        key: "email",
        width: "21%",

        render: (email) => (
            <span className="table-cell-container">
                <div className="table-cell-icon">
                    <EmailIcon />{" "}
                </div>
                {email}
            </span>
        )
    },
    {
        title: "Client Name",
        dataIndex: "clientName",
        key: "clientName",
        width: "24%"
    },
    {
        title: "Facility Name",
        dataIndex: "facilityName",
        key: "facilityName",
        width: "21%",
        render: (_text, record) => {
            const data = record?.facilityNames || [];
            const remainingData = record?.facilityNames?.slice(1) || [];

            if (data.length > 0) {
                const content = (
                    <div>
                        {remainingData.map((item, index) => (
                            <div key={index}>
                                <span>{item}</span>
                            </div>
                        ))}
                    </div>
                );

                return (
                    <div className="facility-names-wrapper">
                        <div className="facility-name">{data[0]}</div>
                        <div className="remaining-facility-count">
                            {remainingData.length > 0 && (
                                <PopOver content={content}>
                                    <Text
                                        className="popover-workorder-text"
                                        underline={true}
                                    >{`+${remainingData.length}`}</Text>
                                </PopOver>
                            )}
                        </div>
                    </div>
                );
            }
        }
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: "9%",

        render: (status) => {
            let color;
            if (status === "Active") color = "rgb( 98, 181, 229)";
            else if (status === "InActive") color = "rgb( 154,154,154)";
            else color = "rgb( 232,119,34)";

            return (
                <span>
                    <span
                        className="status"
                        style={{ backgroundColor: color }}
                    />
                    <span className="status-text">
                        {status ? status : "Pending"}
                    </span>
                </span>
            );
        }
    }
];

const ClientProctors = () => {
    const { width } = useViewPort();
    const breakpoint = 670;
    const history = useHistory();
    const location = useLocation();
    const [clientList, setClientList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [loadingList, setLoadingList] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [filterValue, setFilterValue] = useState(location.state?.clientStatus ?? "");
    const [sourceFilterValue, setSourceFilterValue] = useState(location.state?.source ?? SourceConstants.SALUX_VAULT);
    const [clientSearchText, setClientSearchText] = useState(location.state?.clientSearchText?.trim());

    const customPageSize = 10;
    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 36,
                color: "rgb(0, 48, 135)",
                fontWeight: "bold"
            }}
            spin
        />
    );

    const debounceFn = useCallback(_debounce(handleDebounceFn, 1000), []);

    function handleDebounceFn(searchValue, filterValue, sourceFilterValue) {
        populateClientList(
            0,
            customPageSize,
            searchValue,
            filterValue,
            sourceFilterValue
        );
        history.replace({
            pathname: `/client-proctor`,
            state: {...location.state, clientSearchText: searchValue}
        })
    }

    const handleSearchChange = (searchValue) => {
        setClientSearchText(searchValue);
        debounceFn(searchValue, filterValue, sourceFilterValue);
    };
    const pageHeaderProps = {
        btnAdd: "Add",
        onBtnClick: () => {
            history.push({
                pathname: "/client-proctor-form",
                state: location.state
            });
        }
    };
    const populateClientList = async (
        page,
        size,
        searchValue,
        filterValue,
        sourceFilterValue
    ) => {
        setLoading(true);
        setLoadingList(true);

        const filter = {
            ...(filterValue !== "" && { status: filterValue }),
            ...(sourceFilterValue !== "" && { source: sourceFilterValue })
        };

        const data = await apiCall.getClientProctors(
            page,
            size,
            filter,
            searchValue
        );

        if (data.status === 200) {
            setClientList(data?.data?.docs);
            setTotalCount(data?.data?.totalDocs);
            setCurrentPage(data?.data?.page);
            setLoading(false);
            setLoadingList(false);
        } else {
            ToastNotification("error", "Error fetching User records.");
        }
        setLoading(false);
        setLoadingList(false);
    };

    const loadMoreData = () => {
        if (loadingList) {
            return;
        }

        populateClientList(
            currentPage,
            20,
            clientSearchText,
            filterValue,
            sourceFilterValue
        );
    };

    useEffect(() => {
        if (width > breakpoint) {
            populateClientList(
                0,
                customPageSize,
                clientSearchText,
                filterValue,
                sourceFilterValue
            );
        } else {
            loadMoreData();
        }
    }, [filterValue, sourceFilterValue]);

    useEffect(() => () => {
        if (history.action === "POP") {
            history.go(1);
        }
    });

    const userTypesDropdown = (
        <span className="select">
            <Space>
                <Select
                    defaultValue={SourceConstants.SALUX_VAULT}
                    value={sourceFilterValue}
                    onSelect={(value) => {
                        setSourceFilterValue(value);
                        history.replace({
                            pathname: `/client-proctor`,
                            state: {...location.state, source: value}
                        })
                    }}
                    className="user-type-dropdown-source"
                >
                    <Select.Option value="">All Users</Select.Option>
                    <Select.Option value={SourceConstants.SALUX_VAULT}>
                        Salux Vault
                    </Select.Option>
                    <Select.Option value={SourceConstants.WRE}>
                        WRE
                    </Select.Option>
                </Select>
                <Select
                    defaultValue=""
                    value={filterValue}
                    onSelect={(value) => {
                        setFilterValue(value);
                        history.replace({
                            pathname: `/client-proctor`,
                            state: {...location.state, clientStatus: value}
                        })
                    }}
                    className="user-type-dropdown-status"
                >
                    <Select.Option value="">All Users</Select.Option>
                    <Select.Option value="Active">Active Users</Select.Option>
                    <Select.Option value="InActive">
                        InActive Users
                    </Select.Option>
                    <Select.Option value="Pending">Pending Users</Select.Option>
                </Select>
            </Space>
        </span>
    );

    const showLoader = loading ? (
        <Spin indicator={antIcon} className="loader" />
    ) : (
        <ContentTable
            data={clientList}
            columns={columns}
            onClick={(recordIndex) => ({
                onClick: () => {
                    history.push({
                        pathname: `/client-proctor-form`,
                        state: { ...location.state, id: recordIndex._id }
                    });
                }
            })}
            pagination={{
                pageSize: customPageSize,
                total: totalCount,
                current: currentPage,
                onChange: async (page, pageSize) => {
                    setCurrentPage(page);
                    await populateClientList(
                        page - 1,
                        pageSize,
                        clientSearchText,
                        filterValue,
                        sourceFilterValue
                    );
                }
            }}
        />
    );
    return (
        <div className="main-container" style={{ paddingTop: "6px" }}>
            <PageHead
                title={"Client Proctors"}
                searchPlaceholderTxt="Search"
                // onSearchFunction={onSearchFunction}
                handleSearchChange={handleSearchChange}
                searchValue={clientSearchText}
                dropDown={userTypesDropdown}
                {...pageHeaderProps}
                width={width}
                breakpoint={breakpoint}
            >
                {width > breakpoint ? (
                    showLoader
                ) : (
                    <div
                        id="scrollableDiv"
                        style={{
                            height: 500,
                            overflowY: "auto"
                        }}
                    >
                        <InfiniteScroll
                            dataLength={totalCount}
                            next={loadMoreData}
                            hasMore={totalCount < 100}
                            loader={
                                <Skeleton
                                    avatar
                                    paragraph={{ rows: 1 }}
                                    active
                                />
                            }
                            endMessage={
                                <Divider plain>
                                    It is all, nothing more{" "}
                                </Divider>
                            }
                            scrollableTarget="scrollableDiv"
                            style={{ overflow: "hidden" }}
                        >
                            <ListClients
                                data={clientList}
                                // pagination={{
                                //   pageSize: customPageSize,
                                //   total: totalCount,
                                //   current: currentAdminPage,
                                //   onChange: async (page, pageSize) => {
                                //     setCurrentAdminPage(page);
                                //     await populateAdminList(
                                //       page - 1,
                                //       pageSize,
                                //       adminSearchText
                                //     );
                                //   },
                                // }}
                            />
                        </InfiniteScroll>
                    </div>
                )}
            </PageHead>
        </div>
    );
};
export default ClientProctors;
