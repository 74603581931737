import React, { useState, useEffect } from "react";

import "./App.css";
import AuthRoutes from "./routes/authRoutes";
import ResponsiveContext from "./services/responsiveContext";
import SessionTimeout from "./services/sessionTimeout";

const App = () => {
    const ViewPortProvider = ({ children }) => {
        const [width, setWidth] = useState(window.innerWidth);
        const [height, setHeight] = useState(window.innerHeight);
        const handleWindowResize = () => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        };

        useEffect(() => {
            window.addEventListener("resize", handleWindowResize);
            return () =>
                window.removeEventListener("resize", handleWindowResize);
        }, []);

        return (
            <ResponsiveContext.Provider value={{ width, height }}>
                {children}
                <SessionTimeout />
            </ResponsiveContext.Provider>
        );
    };

    return (
        <div className="App">
            <ViewPortProvider>
                <AuthRoutes />
                <SessionTimeout />
            </ViewPortProvider>
        </div>
    );
};

export default App;
