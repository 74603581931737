import React, { useState } from "react";
import useViewPort from "../services/responsiveHelper";
import { Layout } from "antd";
import SidebarContent from "./SidebarContent";

const { Sider } = Layout;

const SideBar = () => {
    const { width } = useViewPort();
    const breakpoint = 991;
    const [collapsed, setCollapse] = useState(false);

    const onCollapse = (isCollapsed) => {
        setCollapse(isCollapsed);
    };

    return (
        <>
            {width > breakpoint ? (
                <Sider
                    breakpoint="lg"
                    onCollapse={onCollapse}
                    collapsed={collapsed}
                    style={{
                        overflowY: "auto",
                        height: "100vh",
                        position: "relative",
                        left: 0
                    }}
                >
                    <SidebarContent />
                </Sider>
            ) : null}
        </>
    );
};

export default SideBar;
