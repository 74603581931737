import React from "react";
import { Button, Affix, Switch } from "antd";
import { PropTypes } from "prop-types";

const FooterMenu = (props) => {
    const {
        btnType,
        btnAdd,
        onBtnClick,
        btnUpdate,
        toggleStatus,
        onChangeSwitch,
        defaultChecked,
        switchDisabled,
        formName,
        onBtnEdit,
        toggleClassname
    } = props;

    const setButtonType = btnType ? btnType : "primary";
    const setFormName = formName ? formName : null;
    const extraTablePageHeaderContent = [
        toggleStatus ? (
            <span className="switch-status">
                <Switch
                    onClick={onChangeSwitch}
                    disabled={switchDisabled}
                    checked={defaultChecked}
                    key="switchStatus"
                    className={toggleClassname}
                />
                {toggleStatus}
            </span>
        ) : null,
        btnAdd ? (
            <Button
                type={setButtonType}
                onClick={onBtnClick}
                key="actionBtn"
                shape="round"
                form={setFormName}
            >
                {btnAdd}
            </Button>
        ) : null,
        btnUpdate ? (
            <Button
                type="secondary"
                htmlType="button"
                onClick={onBtnEdit}
                key="clearActionBtn"
                shape="round"
                className="delete-btn-none"
                style={{
                    marginRight: "0px"
                }}
                form={setFormName}
            >
                {btnUpdate}
            </Button>
        ) : null
    ];

    /** Useful When Data View */
    return (
        <Affix offsetBottom={10}>
            {/* <Button type="primary" onClick={() => setBottom(bottom + 10)}>
        Affix bottom
      </Button> */}
            <div className="page-header footer">
                {extraTablePageHeaderContent}{" "}
            </div>
        </Affix>
    );
};

FooterMenu.propTypes = {
    backBtnPath: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string
};

export default FooterMenu;
