import React from "react";
import { Typography } from "antd";

const Copyright = () => (
    <div className="copyright-container">
        <Typography
            style={{
                color: "rgb(154, 154,154)"
            }}
        >
            Copyright {new Date().getFullYear()} © All Rights Reserved
        </Typography>
    </div>
);

export default Copyright;
