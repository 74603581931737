import React from "react";
import { Table } from "antd";

const ContentTable = (props) => {
    const { data, columns, pagination, onClick } = props;
    return (
        <Table
            onRow={onClick}
            dataSource={data}
            columns={columns}
            rowKey={(record) => record._id}
            pagination={{
                defaultPageSize: 10,
                hideOnSinglePage: true,
                showSizeChanger: false,
                responsive: true,
                ...pagination
            }}
            rowClassName={(_record, index) =>
                index % 2 === 0
                    ? "eCloud-table-row-light"
                    : "eCloud-table-row-dark"
            }
            size="middle"
            className="eCloud-table"
        />
    );
};

export default ContentTable;
