import React, { useState } from "react";
import "../../styles/login.css";
import apiCall from "../../services/apiCall";
import { ReactComponent as Logo } from "../../assets/logo-original.svg";
import { ReactComponent as BackLogo } from "../../assets/back-icon.svg";
import { ReactComponent as ValidationLogo } from "../../assets/icon_alert.svg";
import ToastNotification from "../../helpers/ToastNotification";
import "antd/dist/antd.css";
import { Form, Input, Button, Card, Typography, Row, Col } from "antd";
import { useHistory } from "react-router-dom";
import Copyright from "../../components/Copyright";

const ForgotPassword = () => {
    const [form] = Form.useForm();
    const [emailValidation, setEmailValidation] = useState({
        status: false
    });
    const history = useHistory();
    const onFinish = async (values) => {
        const response = await apiCall.sendForgotPasswordLink(values.email);

        if (response?.status === 200) {
            history.push({
                pathname: "/email-confirmation",
                state: { confirmEmail: values.email }
            });
        } else if (response?.status === 400) {
            setEmailValidation({ state: true, message: response.data });
        } else {
            console.log(response?.data);
            ToastNotification("error", response?.data);
        }
    };

    const goToLogin = () => {
        history.push({
            pathname: "/login"
        });
    };
    return (
        <div className="login-container">
            <Row align="middle" style={{ width: "100%" }}>
                <Col
                    xs={{ span: 20, offset: 2 }}
                    sm={{ span: 18, offset: 3 }}
                    md={{ span: 16, offset: 4 }}
                    lg={{ span: 14, offset: 5 }}
                    xl={{ span: 10, offset: 7 }}
                    className="box-modal"
                >
                    <Row justify="center" align="top">
                        <div className="logo-container">
                            <Logo />
                        </div>
                    </Row>
                    <Card className="login-box">
                        <Form
                            form={form}
                            requiredMark={false}
                            name="normal_login"
                            layout="vertical"
                            className="login-form"
                            initialValues={{
                                remember: true
                            }}
                            onFinish={onFinish}
                        >
                            <div className="logo-back">
                                <BackLogo onClick={goToLogin} />
                            </div>
                            <Typography className="login-title">
                                {" "}
                                Enter Email{" "}
                            </Typography>
                            <Typography className="login-description">
                                You will receive the password reset link on this
                                email.
                            </Typography>
                            <Form.Item
                                name="email"
                                label="Email"
                                rules={[
                                    {
                                        required: true,
                                        type: "email",
                                        message: "Please enter valid Email."
                                    }
                                ]}
                            >
                                <Input
                                    placeholder="Ex: John@gmail.com"
                                    autoFocus
                                />
                            </Form.Item>
                            {emailValidation.state ? (
                                <div className="login-validation-container">
                                    <div>
                                        <ValidationLogo />
                                    </div>
                                    <Typography className="login-validation-text">
                                        {emailValidation.message}
                                    </Typography>
                                </div>
                            ) : null}
                            <Form.Item>
                                <div className="login-button-container">
                                    <Button
                                        type="link"
                                        size="small"
                                        onClick={goToLogin}
                                    >
                                        Back to Login
                                    </Button>
                                    <Button
                                        type="primary"
                                        shape="round"
                                        htmlType="submit"
                                    >
                                        Continue
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>
                    </Card>
                    <Row justify="center" align="bottom">
                        <Copyright />
                    </Row>
                </Col>
            </Row>
        </div>
    );
};
export default ForgotPassword;
