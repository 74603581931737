import { notification } from "antd";

const toastNotification = (state, message) => {
    notification[state]({
        message: message,
        placement: "bottomLeft",
        duration: 3
    });
};

export default toastNotification;
