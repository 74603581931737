import React from "react";
import Login from "../pages/Login/Login";
import ForgotPassword from "../pages/Login/ForgotPassword";
import ClientActivated from "../pages/Login/ClientActivated";
import ResetLink from "../pages/Login/ResetLink";
import ResetPassword from "../pages/Login/ResetPassword";
import CreatePassword from "../pages/Login/CreatePassword";
import ExamineticsAdmin from "../pages/Dashboard/ExamineticsAdmin";
import ClientProctors from "../pages/Dashboard/ClientProctors";
import ExamineticsAdminForm from "../pages/Dashboard/ExamineticsAdminForm";
import ClientProctorForm from "../pages/Dashboard/ClientProctorForm";

export const RouteConstants = {
    Others: {
        Login: {
            component: <Login />,
            path: "/login"
        },
        ForgotPassword: {
            component: <ForgotPassword />,
            path: "/forgot-password"
        },
        ResetLink: {
            component: <ResetLink />,
            path: "/email-confirmation"
        },
        CreatePassword: {
            component: <CreatePassword />,
            path: "/create-Password"
        },
        ResetPassword: {
            component: <ResetPassword />,
            path: "/reset-password"
        },
        ClientActivated: {
            component: <ClientActivated />,
            path: "/client-activated"
        }
    },
    HomePage: {
        ExamineticsAdmin: {
            component: <ExamineticsAdmin />,
            path: "/examinetics-admin"
        },
        ClientProctors: {
            component: <ClientProctors />,
            path: "/client-proctor"
        },
        ExamineticsAdminForm: {
            component: <ExamineticsAdminForm />,
            path: "/examinetics-admin-form"
        },
        ClientProctorForm: {
            component: <ClientProctorForm />,
            path: "/client-proctor-form"
        }
    }
};

export const SourceConstants = {
    WRE: "WRE",
    SALUX_VAULT: "SALUX_VAULT"
}