import { Popover } from "antd";
import React from "react";

const PopOver = ({
    placement = "right",
    title,
    content = "",
    message = "",
    children
}) => (
    <Popover placement={placement} title={title} content={content}>
        {message}
        {children}
    </Popover>
);

export default PopOver;
