import React from "react";
import "../../styles/login.css";
import { ReactComponent as Logo } from "../../assets/logo-original.svg";
import "antd/dist/antd.css";
import { Form, Card, Typography, Row, Col } from "antd";
import Copyright from "../../components/Copyright";

const ClientActivated = () => (
    <div className="login-container">
        <Row align="middle" style={{ width: "100%" }}>
            <Col
                xs={{ span: 20, offset: 2 }}
                sm={{ span: 18, offset: 3 }}
                md={{ span: 16, offset: 4 }}
                lg={{ span: 14, offset: 5 }}
                xl={{ span: 10, offset: 7 }}
                className="box-modal"
            >
                <Row justify="center" align="top">
                    <div className="logo-container">
                        <Logo />
                    </div>
                </Row>
                <Card className="login-box">
                    <Form
                        name="normal_login"
                        layout="vertical"
                        className="login-form"
                    >
                        <Typography className="login-title">
                            {" "}
                            Your Client Proctor Account has been activated.
                        </Typography>

                        <Typography className="login-description">
                            You will be able to login Client Proctor Account on
                            your device.
                        </Typography>
                    </Form>
                </Card>
                <Row justify="center" align="bottom">
                    <Copyright />
                </Row>
            </Col>
        </Row>
    </div>
);
export default ClientActivated;
