import React from "react";
import { Modal } from "antd";
import useViewPort from "../services/responsiveHelper";

const ConfirmModal = (props) => {
    const { title, content, visible, onConfirm, onCancel } = props;
    const { width } = useViewPort();
    const breakpoint = 670;
    return (
        <div className="confirm-modal">
            <Modal
                title={title}
                centered
                visible={visible}
                onOk={onConfirm}
                onCancel={onCancel}
                okText="Confirm"
                cancelText="Cancel"
                closable={false}
                maskClosable={false}
                width={width > breakpoint ? 618 : 280}
            >
                <p>{content}</p>
            </Modal>
        </div>
    );
};

export default ConfirmModal;
