import React, { useState, useEffect } from "react";
import { Layout, Menu, Image, Button, Drawer } from "antd";
import useViewPort from "../services/responsiveHelper";
import SidebarContent from "./SidebarContent";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import logo from "../assets/logo-original-white.svg";
import { ReactComponent as MenuIcon } from "../assets/menuDrawer.svg";

const { Header } = Layout;

const MainHeader = () => {
    const { width } = useViewPort();
    const breakpoint = 991;
    const currentLocation = useLocation();
    const [collapsed, setCollapse] = useState(false);
    const [selectedKey, setSelectedKey] = useState("1");

    const showDrawer = () => {
        setCollapse(true);
    };
    const onClose = () => {
        setCollapse(false);
    };

    useEffect(() => {
        if (
            currentLocation.pathname === "/examinetics-admin" ||
            currentLocation.pathname === "/examinetics-admin-form"
        )
            setSelectedKey("1");
        else setSelectedKey("2");
    }, [currentLocation]);

    const examineticsAdminForm =
        window.location.pathname !== "/examinetics-admin-form" ? (
            <>
                <Header className="mobile-header">
                    <Button
                        type="primary"
                        icon={<MenuIcon />}
                        onClick={showDrawer}
                        style={{ marginTop: "10px" }}
                    />
                    <div className="title">
                        <Image
                            src={logo}
                            width="9.7rem"
                            height="3.125rem"
                            preview={false}
                        />
                    </div>
                </Header>
                <Header>
                    <Menu mode="horizontal" defaultSelectedKeys={["1"]}>
                        <Menu.Item key="1">
                            <NavLink
                                to={{
                                    pathname: "/examinetics-admin",
                                    state: currentLocation.state
                                }}
                            >
                                Examinetics Admins
                            </NavLink>
                        </Menu.Item>

                        <Menu.Item key="2">
                            <NavLink
                                to={{
                                    pathname: "/client-proctor",
                                    state: currentLocation.state
                                }}
                            >
                                Client Proctors
                            </NavLink>
                        </Menu.Item>
                    </Menu>
                </Header>
                <Drawer
                    placement="top"
                    onClose={onClose}
                    // closable={true}
                    visible={collapsed}
                    className="mobile-sidebar"
                    width="50%"
                >
                    <SidebarContent />
                </Drawer>
            </>
        ) : null;

    return width > breakpoint ? (
        <div className="header">
            <Header>
                <Menu
                    mode="horizontal"
                    selectedKeys={[selectedKey]}
                    // onClick={handleClick}
                >
                    <Menu.Item key="1">
                        <NavLink
                            to={{
                                pathname: "/examinetics-admin",
                                state: currentLocation.state
                            }}
                        >
                            Examinetics Admins
                        </NavLink>
                    </Menu.Item>

                    <Menu.Item key="2">
                        <NavLink
                            to={{
                                pathname: "/client-proctor",
                                state: currentLocation.state
                            }}
                        >
                            Client Proctors
                        </NavLink>
                    </Menu.Item>
                </Menu>
            </Header>
        </div>
    ) : (
        examineticsAdminForm
    );
};

export default withRouter(MainHeader);
