import React, { useState, useEffect } from "react";
import { Form, Row, Col, Input, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import PageHead from "../../components/PageHead";
import FooterMenu from "../../components/FooterMenu";
import ToastNotification from "../../helpers/ToastNotification";
import apiCall from "../../services/apiCall";
import ConfirmModal from "../../components/ConfirmModal";
import { ReactComponent as ValidationLogo } from "../../assets/icon_alert.svg";
import useViewPort from "../../services/responsiveHelper";
import { useHistory, useLocation } from "react-router-dom";
import { removeIdFieldStateData } from "../../helpers/StateHelper";

const ExamineticsAdminForm = () => {
    const history = useHistory();
    const location = useLocation();
    const [form] = Form.useForm();

    const userId = location?.state?.id;
    const [disableForm, setDisableForm] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalVisibleActivate, setModalVisibleActivate] = useState(false);
    const [showSave, setShowSave] = useState(false);
    const [disableEmail, setDisableEmail] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userValue, setUserValue] = useState();
    const [status, setStatus] = useState();
    const { width } = useViewPort();
    const breakpoint = 670;
    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 36,
                color: "rgb(0, 48, 135)",
                fontWeight: "bold"
            }}
            spin
        />
    );

    const checkInActive = status === "InActive" ? "Inactive" : null;
    const checkStatus = status === "Active" ? "Active" : checkInActive;
    const checkClassInActive = status === "InActive" ? "switch-disabled" : null;
    const checkClassName = status === "Active" ? "switch" : checkClassInActive;
    const phShowSaveIf = {
        title: userValue?.firstName + " " + userValue?.lastName,
        toggleStatus: checkStatus,
        onChangeSwitch: (change) => {
            if (!change) setModalVisible(true);
            else if (change) setModalVisibleActivate(true);
        },
        toggleClassname: checkClassName,
        switchDisabled: false,
        defaultChecked: status === "Active" ? true : false,
        btnAdd: "Save",
        backBtnPath: "/examinetics-admin",
        routeState: removeIdFieldStateData(location.state)
    };
    const phShowSaveElse = {
        title: userValue?.firstName + " " + userValue?.lastName,
        toggleStatus: checkStatus,
        switchDisabled: true,
        onChangeSwitch: (change) => {
            if (!change) setModalVisible(true);
            else if (change) setModalVisibleActivate(true);
        },
        defaultChecked: status === "Active" ? true : false,
        toggleClassname: checkClassName,
        btnUpdate: "Edit",
        backBtnPath: "/examinetics-admin",
        routeState: removeIdFieldStateData(location.state)
    };
    const checkShowSave = showSave ? phShowSaveIf : phShowSaveElse;
    const checkUserValueIf = userValue?.email
        ? checkShowSave
        : {
              title: "New Admin",
              btnAdd: "Save",
              backBtnPath: "/examinetics-admin",
              routeState: removeIdFieldStateData(location.state)
          };
    const checkUserValueElse = userValue?.email
        ? {
              title: userValue?.firstName + " " + userValue?.lastName,
              backBtnPath: "/examinetics-admin",
              routeState: removeIdFieldStateData(location.state)
          }
        : {
              title: "New Admin",
              backBtnPath: "/examinetics-admin",
              routeState: removeIdFieldStateData(location.state)
          };
    const pageHeaderProps =
        width > breakpoint ? checkUserValueIf : checkUserValueElse;

    // const pageHeaderPropsResponsive = userValue?.email
    //   ? {
    //       title: userValue?.firstName + " " + userValue?.lastName,
    //       backBtnPath: "/examinetics-admin",
    //     }
    //   : {
    //       title: "New Admin",
    //       backBtnPath: "/examinetics-admin",
    //     };

    const pfShowSave = showSave
        ? {
              toggleStatus: checkStatus,
              onChangeSwitch: (change) => {
                  if (!change) setModalVisible(true);
                  else if (change) setModalVisibleActivate(true);
              },
              toggleClassname: checkClassName,
              switchDisabled: false,
              defaultChecked: status === "Active" ? true : false,
              btnAdd: "Save",
              backBtnPath: "/examinetics-admin",
              routeState: removeIdFieldStateData(location.state)
          }
        : {
              toggleStatus: checkStatus,
              switchDisabled: true,
              onChangeSwitch: (change) => {
                  if (!change) setModalVisible(true);
                  else if (change) setModalVisibleActivate(true);
              },
              defaultChecked: status === "Active" ? true : false,
              toggleClassname: checkClassName,
              btnUpdate: "Edit"
          };
    const pageFooterProps = userValue?.email
        ? pfShowSave
        : {
              btnAdd: "Save"
          };
    const confirmHandleModal = () => {
        setModalVisible(false);
        setStatus("InActive");
    };
    const confirmHandleModalActivate = () => {
        setModalVisibleActivate(false);
        setStatus("Active");
    };
    const formatContactNumber = (value) => {
        value = value.replace(/[^\d]/g, "");
        if (!value) return "";

        const valueLength = value.length;
        if (valueLength < 4) return value;
        if (valueLength < 7) return `(${value.slice(0, 3)}) ${value.slice(3)}`;
        return `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(
            6,
            10
        )}`;
    };
    const onContactNumberInputChange = (e) => {
        const value = e.target.value;
        const formattedNumber = formatContactNumber(value);
        form.setFieldsValue({
            [e.target.id]: formattedNumber
        });
    };
    const onFinish = (formValues) => {
        if (!formValues.phone) delete formValues["phone"];
        if (userValue?.email) {
            if (status && status !== "Pending") {
                formValues.status = status;
            }
            editUser(formValues);
        } else {
            addUser(formValues);
        }
    };

    /** API calls */
    const getUser = async (user_Id) => {
        setLoading(true);
        const data = await apiCall.getUserByEmail(user_Id);
        if (data.status === 200) {
            setStatus(data.data.status);
            setUserValue(data.data);
        } else {
            ToastNotification("error", "Error fetching User records.");
        }
        setLoading(false);
    };
    const resendInvite = async () => {
        setLoading(true);
        const data = await apiCall.resendInvite(userValue.email);
        if (data.status === 200) {
            ToastNotification(
                "success",
                data ? data?.data : " Reset Activation link sent."
            );
        } else {
            ToastNotification("error", "Something went wrong.");
        }
        setLoading(false);
    };
    const addUser = async (formValues) => {
        setLoading(true);
        const data = await apiCall.addAdminUser(formValues);
        if (data.status === 200) {
            setUserValue(data.data);
            setShowSave(false);
            setDisableForm(true);
            setDisableEmail(true);
            ToastNotification("success", "Record saved successfully.");
            history.push({
                pathname: `/examinetics-admin-form`,
                state: { ...location.state, id: data.data._id }
            });
        } else if (data.status === 400) {
            setLoading(false);
            form.setFields([
                {
                    name: "email",
                    errors: [data ? data.data : "Something went wrong."]
                }
            ]);
        } else {
            setLoading(false);
            ToastNotification("error", data?.data);
        }
        setLoading(false);
    };

    const editUser = async (formValues) => {
        setLoading(true);
        const data = await apiCall.editAdminUser(formValues);
        if (data.status === 200) {
            setUserValue(formValues);
            setShowSave(false);
            setDisableForm(true);
            setDisableEmail(true);
            ToastNotification("success", "Record saved successfully.");
        } else {
            setLoading(false);
            ToastNotification("error", "Error fetching User records.");
        }
        setLoading(false);
    };

    useEffect(() => {
        if (userId && userId !== null) {
            setDisableForm(true);
            setDisableEmail(true);
            getUser(userId);
        }
    }, [userId]);

    const displayPending =
        status === "Pending" ? (
            <Col span={24}>
                <div className="login-validation-container">
                    <div>
                        <ValidationLogo />
                    </div>

                    <span className="login-validation-text">
                        Pending user activation.
                    </span>
                    <span className="resend-activation-link">
                        <span
                            className="resend-activation-link-text"
                            onClick={resendInvite}
                        >
                            Resend Activation Link
                        </span>
                    </span>
                </div>
            </Col>
        ) : null;

    const showFooterMenu =
        width < breakpoint ? (
            <FooterMenu
                {...pageFooterProps}
                formName="clientProctorAddEditForm"
                onBtnClick={() => {
                    form.submit();
                }}
                onBtnEdit={() => {
                    setShowSave(true);
                    setDisableForm(false);
                }}
            />
        ) : null;
    return loading ? (
        <Spin indicator={antIcon} className="loader" />
    ) : (
        <div className="main-container">
            <PageHead
                formName="clientProctorAddEditForm"
                {...pageHeaderProps}
                onBtnClick={() => {
                    form.submit();
                }}
                onBtnEdit={() => {
                    setShowSave(true);
                    setDisableForm(false);
                }}
                divider={true}
            >
                <Row
                    align="middle"
                    style={{
                        marginTop: "30px",
                        marginLeft: "16px",
                        marginRight: "16px"
                    }}
                >
                    <Col
                        xs={{ span: 22, offset: 1 }}
                        sm={{ span: 20, offset: 2 }}
                        md={{ span: 18, offset: 3 }}
                        lg={{ span: 16, offset: 4 }}
                        xl={{ span: 14, offset: 5 }}
                        xxl={{ span: 14, offset: 5 }}
                    >
                        <Form
                            form={form}
                            name="examineticsAdminAddEditForm"
                            size="large"
                            colon="false"
                            scrollToFirstError="true"
                            onFinish={onFinish}
                            layout="vertical"
                            requiredMark={true}
                            className="login-form"
                        >
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        label="Email"
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                type: "email",
                                                message:
                                                    "Please enter valid Email."
                                            }
                                        ]}
                                        initialValue={userValue?.email}
                                    >
                                        <Input
                                            placeholder="Ex: jonathan@gmail.com"
                                            disabled={disableEmail}
                                            autoFocus
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify="space-between">
                                <Col span={11}>
                                    <Form.Item
                                        label="First Name"
                                        name="firstName"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Please enter First Name."
                                            }
                                        ]}
                                        initialValue={userValue?.firstName}
                                    >
                                        <Input
                                            placeholder="Ex: Tanya "
                                            disabled={disableForm}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item
                                        label="Last Name"
                                        name="lastName"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Please enter Last Name."
                                            }
                                        ]}
                                        initialValue={userValue?.lastName}
                                    >
                                        <Input
                                            placeholder="Ex: Jonathan"
                                            disabled={disableForm}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        label="Phone"
                                        name="phone"
                                        rules={[
                                            {
                                                type: "string",
                                                allowNull: true,
                                                pattern:
                                                    /^(\([0-9]{3}\) [0-9]{3}-[0-9]{4})$/,
                                                message:
                                                    "The entered contact number seems to be invalid."
                                            }
                                        ]}
                                        initialValue={userValue?.phone}
                                    >
                                        <Input
                                            placeholder="Ex: (xxx) xxx-xxxx"
                                            onChange={
                                                onContactNumberInputChange
                                            }
                                            id="phone"
                                            disabled={disableForm}
                                        />
                                    </Form.Item>
                                </Col>
                                {displayPending}
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </PageHead>
            {showFooterMenu}
            <ConfirmModal
                title="De-activate user?"
                content="Please confirm if you wish to de-activate this user."
                visible={modalVisible}
                onConfirm={confirmHandleModal}
                onCancel={() => {
                    setModalVisible(false);
                    setStatus("Active");
                }}
            />
            <ConfirmModal
                title="Activate user?"
                content="Please confirm if you wish to Activate this user."
                visible={modalVisibleActivate}
                onConfirm={confirmHandleModalActivate}
                onCancel={() => {
                    setModalVisibleActivate(false);
                    setStatus("InActive");
                }}
            />
        </div>
    );
};
export default ExamineticsAdminForm;
