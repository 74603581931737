import React, {
    useState,
    useEffect,
    useCallback,
    useRef,
    Fragment
} from "react";
import moment from "moment";

const SessionTimeout = () => {
    const url = window.location.href;
    const loginUrl = url.substring(0, url.lastIndexOf("/"));
    const events = ["click", "load", "scroll"];
    const [second, setSecond] = useState(0);
    const [isOpen, setOpen] = useState(false);
    const token = window.localStorage.getItem("token");

    let timeStamp;
    let warningInactiveInterval = useRef();
    let startTimerInterval = useRef();

    // start inactive check
    let timeChecker = useCallback(() => {
        startTimerInterval.current = setTimeout(() => {
            let storedTimeStamp = localStorage.getItem("lastTimeStamp");
            warningInactive(storedTimeStamp);
        }, 60000);
    }, []);

    // warning timer
    const warningInactive = (timeString) => {
        clearTimeout(startTimerInterval.current);

        warningInactiveInterval.current = setInterval(() => {
            const maxTime = 2;
            const popTime = 1;

            const diff = moment.duration(moment().diff(moment(timeString)));
            const minPast = diff.minutes();
            const leftSecond = 60 - diff.seconds();

            if (minPast === popTime) {
                setSecond(leftSecond);
                console.log(second);
                setOpen(true);
            }

            if (minPast === maxTime) {
                clearInterval(warningInactiveInterval.current);
                setOpen(false);
                localStorage.removeItem("lastTimeStamp");
                logout();
            }
        }, 1000);
    };
    const logout = () => {
        window.location.replace(`${loginUrl}/login`);
        localStorage.clear();
    };

    // reset interval timer
    let resetTimer = useCallback(() => {
        clearTimeout(startTimerInterval.current);
        clearInterval(warningInactiveInterval.current);

        if (token) {
            timeStamp = moment();
            localStorage.setItem("lastTimeStamp", timeStamp);
        } else {
            clearInterval(warningInactiveInterval.current);
            localStorage.removeItem("lastTimeStamp");
        }
        timeChecker();
        setOpen(false);
    }, [token]);

    useEffect(() => {
        events.forEach((event) => {
            window.addEventListener(event, resetTimer);
        });
        timeChecker();
        return () => {
            clearTimeout(startTimerInterval.current); //   resetTimer();
        };
    }, [resetTimer, events, timeChecker]);

    if (!isOpen) {
        return null;
    }

    // change fragment to modal and handle close func to close
    return <Fragment />;
};

export default SessionTimeout;
