import React from "react";
import ResponsiveContext from "./responsiveContext";

// set the defaults
// const useViewPort = () => {
//   const [width, setWidth] = useState(window.innerWidth);

//   useEffect(() => {
//     const handleWindowResize = () => setWidth(window.innerWidth);
//     window.addEventListener("resize", handleWindowResize);
//     return () => window.removeEventListener("resize", handleWindowResize);
//   }, []);

//   // Return the width so we can use it in our components
//   return { width };
// };

const useViewPort = () => {
    const { width, height } = React.useContext(ResponsiveContext);
    return { width, height };
};

export default useViewPort;
