import { Button, Col, Modal, Row, Select, Typography } from "antd";
import React, { useState } from "react";
import DeleteIcon from "../assets/icon_delete.svg";
import moment from "moment";

const WorkorderCard = ({
    data,
    removeWorkOrder,
    updateWorkOrder,
    disabled
}) => {
    const { Text } = Typography;
    const [testType, setTestType] = useState(data.testType);

    const stringToDate = (date) => {
        if (date) {
            return moment(date, "YYYYMMDD").format("MMM DD, YYYY");
        }
        return date;
    };

    const showRemovePopUp = (workOrderNo) => {
        Modal.confirm({
            title: "Confirm",
            content: "Are you sure you want to remove this workorder?",
            onOk() {
                removeWorkOrder(workOrderNo);
            },
            onCancel() {}
        });
    };

    const handleOnSelect = (value) => {
        setTestType(value);
        updateWorkOrder(data.workOrderNo, value);
    };
    return (
        <div className="workorder-details-card">
            <Row justify="space-between" align="middle">
                <Col flex={"auto"}>
                    Workorder: <Text strong>{data.workOrderNo}</Text>{" "}
                </Col>
                <Col flex={"none"}>
                    {!disabled && (
                        <Button
                            type="text"
                            onClick={() => showRemovePopUp(data.workOrderNo)}
                            disabled={disabled}
                            className="remove-workorder-button"
                        >
                            <Text type="danger">
                                <img src={DeleteIcon} alt="Delete Icon" width={15}/>{" "}
                                Remove
                            </Text>
                        </Button>
                    )}
                </Col>
            </Row>
            <Row className="workorder-details-container">
                <Col span={7} className="workorder-details">
                    <Text type="secondary">Organization</Text>
                    <Text>{data.clientName}</Text>
                </Col>
                <Col span={7} className="workorder-details">
                    <Text type="secondary">Facility</Text>
                    <Text>{data.facilityName}</Text>
                </Col>
                <Col span={5} className="workorder-details">
                    <Text type="secondary">Start Date</Text>
                    <Text>{stringToDate(data.startDate)}</Text>
                </Col>
                <Col span={5} className="workorder-details">
                    <Text type="secondary">End Date</Text>
                    <Text>{stringToDate(data.endDate)}</Text>
                </Col>
            </Row>

            <Row className="workorder-details-container">
                <Col span={14} className="workorder-details">
                    <Text type="secondary">Address</Text>
                    <Text>{data.woAddressLine1}</Text>
                </Col>
                <Col span={6} className="workorder-details">
                    <Text type="secondary">Subscription Type</Text>
                    <Select
                        className="select-subscription-type"
                        value={testType}
                        size="medium"
                        onSelect={(value) => handleOnSelect(value)}
                        disabled={disabled}
                    >
                        <Select.Option value="TO">Test Only(TO)</Select.Option>
                        <Select.Option value="TN">
                            Test Notify(TN)
                        </Select.Option>
                        <Select.Option value="TT">Test Train(TT)</Select.Option>
                        <Select.Option value="TC">
                            Test Counsel(TC)
                        </Select.Option>
                        <Select.Option value="TTN">
                            Test Train Notify(TTN)
                        </Select.Option>
                        <Select.Option value="TTC">
                            Test Train Counsel(TTC)
                        </Select.Option>
                    </Select>
                </Col>
            </Row>
        </div>
    );
};

export default WorkorderCard;
