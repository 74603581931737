import axios from "axios";

// eslint-disable-next-line no-undef
axios.defaults.baseURL = process.env.REACT_APP_API_KEY;

function setJWT(jwt) {
    axios.defaults.headers.common = { Authorization: `Bearer ${jwt}` };
}

const httpService = {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    patch: axios.patch,
    setJWT
};
export default httpService;
