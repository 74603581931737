import React from "react";
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect
} from "react-router-dom";
import HomePage from "../pages/Dashboard/HomePage";
import { RouteConstants } from "../helpers/Constants";
const {
    Others: { Login },
    HomePage: { ExamineticsAdmin }
} = RouteConstants;
const AuthRoutes = () => (
    <Router>
        <Switch>
            <Route
                exact
                path="/"
                render={() =>
                    localStorage.getItem("token") ? (
                        <Redirect to={ExamineticsAdmin.path} />
                    ) : (
                        <Redirect to={Login.path} />
                    )
                }
            />
            {Object.entries(RouteConstants.Others).map((item) => (
                <Route key={item[1].path} path={item[1].path}>
                    {item[1].component}
                </Route>
            ))}
            <HomePage>
                {Object.entries(RouteConstants.HomePage).map((item) => (
                    <Route key={item[1].path} path={item[1].path} exact>
                        {item[1].component}
                    </Route>
                ))}
            </HomePage>
            {!localStorage.getItem("token") && <Redirect to={Login.path} />}
        </Switch>
    </Router>
);

export default AuthRoutes;

// import React from "react";
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import Login from "../pages/Login/Login";
// import ForgotPassword from "../pages/Login/ForgotPassword";
// import ResetLink from "../pages/Login/ResetLink";
// import ResetPassword from "../pages/Login/ResetPassword";
// import CreatePassword from "../pages/Login/CreatePassword";
// import ExamineticsAdmin from "../pages/Dashboard/ExamineticsAdmin";
// import ClientProctors from "../pages/Dashboard/ClientProctors";
// import ExamineticsAdminForm from "../pages/Dashboard/ExamineticsAdminForm";
// import ClientProctorForm from "../pages/Dashboard/ClientProctorForm";
// import PrivateRoute from "./PrivateRoute";
// import PublicRoute from "./PublicRoute";

// import { Redirect } from "react-router-dom";
// import HomePage from "../pages/Dashboard/HomePage";

// const AuthRoutes = (props) => {
//   return (
//     <Router>
//       <Switch>
//         {/* <Redirect exact from="/" to="/login" /> */}
//         <Route
//           exact
//           path="/"
//           render={() => {
//             return localStorage.getItem("token") ? (
//               <Redirect to="/examinetics-admin" />
//             ) : (
//               <Redirect to="/login" />
//             );
//           }}
//         />
//         <PublicRoute restricted={true} component={Login} path="/login" exact />
//         {!localStorage.getItem("token") && <Redirect to="/login" />}
//         <PublicRoute
//           restricted={true}
//           component={ForgotPassword}
//           path="/forgot-password"
//           exact
//         />
//         <PublicRoute
//           restricted={true}
//           component={ResetLink}
//           path="/email-confirmation"
//           exact
//         />
//         <PublicRoute
//           restricted={true}
//           component={CreatePassword}
//           path="/create-Password"
//           exact
//         />
//         <PublicRoute
//           restricted={true}
//           component={ResetPassword}
//           path="/reset-password"
//           exact
//         />
//         <HomePage>
//           <PrivateRoute
//             component={ExamineticsAdmin}
//             path="/examinetics-admin"
//             exact
//           />
//           <PrivateRoute
//             component={ClientProctors}
//             path="/client-proctor"
//             exact
//           />
//           <PrivateRoute
//             component={ExamineticsAdminForm}
//             path="/examinetics-admin-form"
//             exact
//           />
//           <PrivateRoute
//             component={ClientProctorForm}
//             path="/client-proctor-form"
//             exact
//           />
//         </HomePage>
//       </Switch>
//     </Router>
//   );
// };

// export default AuthRoutes;
